import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filenameFromPath'
})
export class FilenameFromPathPipe implements PipeTransform {
  transform(filePath: string): string {
    // Get part after the last \
    const filename = filePath.split('/').pop();
    // Return last part
    return filename;
  }
}
