import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DateService } from 'src/app/core/services/date/date.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit {
  // De starting date from parent
  @Input() date: Date;
  // Set max and min date
  @Input() maxDate?: Date;
  @Input() minDate?: Date;
  // Event Emitter to attend subscribers (parent) on date changes
  @Output() dateChanged = new EventEmitter<Date>();
  // Don't respond on anything when true
  @Input() disabled: boolean;
  // Hide side buttons when true
  @Input() hideButtons: boolean;

  // Max future years allowed in calendar
  public maxYearsInFuture = 10;

  // Oldest data possible
  oldDate = new Date('1900-01-01');

  constructor() {
  }

  ngOnInit() {
  }

  /**
   * Add days to current date
   */
  public addDays(days: number): void {
    if (!this.disabled) {
      this.date = new Date(this.date);
      this.date.setDate(this.date.getDate() + days);
    }
  }

  /**
   * Remove days from current date
   */
  public removeDays(days: number): void {
    if (!this.disabled) {
      this.date = new Date(this.date);
      this.date.setDate(this.date.getDate() - days);
    }
  }

  /**
   * Send new date to parent
   * @param date The new date
   * @param pickedDate Formats to date object when true
   */
  public onDateChanged(date: Date, pickedDate = false): void {
    if (pickedDate) {
      date = new Date(date);
    }
    this.dateChanged.emit(date);
  }
}
