import { Component, Input, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from '../../../environments/environment';
import { getAppInfo } from '../../core/utils';
import { IsAppInfo } from '../../core/interfaces/is-app-info';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  @Input() pageTitle: string;
  @Input() showBackButton: boolean;
  public appInfo: IsAppInfo;

  public develop = !environment.production;

  constructor(private location: Location) {
  }

  ngOnInit() {
    this.appInfo = getAppInfo();
  }

  public back(): void {
    this.location.back();
  }
}
