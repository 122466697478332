import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { MaterialDetails } from '../../core/models/material/material-details';

@Component({
  selector: 'app-materials-modal',
  templateUrl: './materials-modal.component.html',
  styleUrls: ['./materials-modal.component.scss']
})
export class MaterialsModalComponent implements OnInit {
  @Input() materialDetails: MaterialDetails;
  @Input() showChecklistButton: boolean;
  @Input() checklistButtonHandler: () => void;

  constructor(
    private modalController: ModalController
  ) {
  }

  ngOnInit(): void {
  }

  public dismiss(): void {
    this.modalController.dismiss();
  }

  public onChecklistButtonClick(): void {
    this.checklistButtonHandler();
  }

}
