import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-slide-control-bar',
  templateUrl: './slide-control-bar.component.html',
  styleUrls: ['./slide-control-bar.component.scss']
})
export class SlideControlBarComponent implements OnInit, OnChanges {
  @Input() prevActive: boolean;
  @Input() nextActive: boolean;
  @Input() index: number;
  @Input() quantityOfSlides: number;

  @Output() indexChanged = new EventEmitter<number>();
  @Output() slidesCompleted = new EventEmitter<void>();

  constructor() {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.index) {
      this.index = changes.index.currentValue;
    }
  }

  public onPrevClick(index: number): void {
    if (this.prevActive && this.index > 0 && this.prevActive) {
      this.indexChanged.emit(index--);
    }
  }

  public onNextClick(index: number): void {
    if (this.index < this.quantityOfSlides - 1 && this.nextActive) {
      this.indexChanged.emit(index++);
    } else if (this.index === this.quantityOfSlides - 1 && this.nextActive) {
      this.slidesCompleted.emit();
    }
  }
}
