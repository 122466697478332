import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { COLORS } from 'src/constants/colors';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  constructor(private toastController: ToastController) {}

  /**
   * Shows the given messages as a toast.
   * @param message Message to show
   * @param color Optional: Color of toast | Default primary
   * @param duration Optional: Duration of showing | Default 2000
   * @param delay Optional: Delay before showing | Default 250
   */
  public async showToastMessage(
    message: string,
    color = COLORS.primary,
    duration = 2000,
    delay = 250,
    position?: 'top' | 'bottom' | 'middle'
  ) {
    // Create toast
    const toast = await this.toastController.create({
      message,
      duration,
      color,
      position
    });
    // Show toast after delay
    setTimeout(() => {
      toast.present();
    }, delay);
  }
}
